// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1726915151817.6765";
}
// REMIX HMR END

export default function SplashScreen() {
  return <div className="h-screen bg-slate-950 flex flex-col items-center justify-center">
      <p>Med Treatment API</p>
    </div>;
}
_c = SplashScreen;
export const meta = () => {
  return [{
    title: "Adamus Med Treatment"
  }, {
    name: "description",
    content: "."
  }, {
    name: "author",
    content: "KwaminaWhyte"
  }, {
    name: "author",
    content: "Codekid"
  }, {
    name: "og:title",
    content: "Adamus Med Treatment"
  }, {
    name: "og:description",
    content: ""
  }, {
    name: "og:image",
    content: "https://res.cloudinary.com/app-deity/image/upload/v1701282976/qfdbysyu0wqeugtcq9wq.jpg"
  }, {
    name: "og:url",
    content: "https://marry-right.vercel.app"
  }, {
    name: "keywords",
    content: "legal marriages in Ghana, Pastors to bless marriages, Is he/she married?, marriiage under ordinance, cases related to marriages in Ghana, mohammedans, ordinance, traditional, verify my marriage certificate, churches legally certified to bless marriages"
  }];
};
var _c;
$RefreshReg$(_c, "SplashScreen");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;